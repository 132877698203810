.side-menu-container {
  position: fixed;
  width: 250px;
  height: 100%;
  left: 0px;
  top: 65px;
  z-index: 101;/*1051*/
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.side-menu-container a {
  text-decoration: none !important;
}

ul {
  list-style-type: none;
  padding: 20px 20px 20px 16px;
  font-family: "Source sans pro";
  font-weight: normal;
  letter-spacing: 0.01em;
  font-size: 14px;
}

.side-menu-list {
  width: "100%";
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
  padding-right: 20px;
  margin-top: 8px;
  border-radius: 4px;
  border-left: 4px solid transparent;
}

.side-menu-list span {
  color: #4b4b4b !important;
  font-size: 0.85rem;
}

.side-menu-list.active, .side-menu-list:hover {
  border-left-color: rgb(5, 126, 206);/*  #0172CB */
  background: #E8FAFF;/*  #E8F6FC */
}

.side-menu-list.active span/* , .side-menu-list:hover span */{
  /* color: #00425F !important; */
  font-weight: 600;
}

Svg {
  display: inline !important;
  margin-right: 15px;
}