.table-name {
    font-weight: bold;
}

.contract-details {
    background: #D4E1F4;
}

.reporting {
    padding: 0.9rem 1.8rem;
    background: rgba(212, 225, 244, 0.53);
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.vendor-overview,
.reporting,
.products {
    padding: 0.9rem 1.8rem;
    background: rgba(212, 225, 244, 0.53);
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.vendor-overview .overview-title,
.reporting .reporting-title {
    width: 100%;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.reporting .reporting-title {
    margin-left: 3.25rem;
}

.vendor-overview {
    padding-right: 1.25rem;
    position: relative;
}

.vendor-overview .inner {
    background: #F1F4FA;
    width: 100%;
    padding: 1.7rem 4.5rem 0.5rem 4rem;
}

.view {
    color: #00425F;
    cursor: pointer;
}
