body {
  font-family: "Source Sans Pro";
}
.dashboard-container {
  position: absolute;
  left: 252px;
  top: 128px;
  background: #f5f5f5; /* f7f8fc */
  width: calc(100% - 252px);
  /* min-width: 1200px; */
  min-height: calc(100% - 64px);
  padding: 25px 40px 40px;
}

.status-container {
  min-height: 40px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.compartment {
  display: inline-block;
  font-family: "Source Sans Pro" !important;
  position: relative;
  vertical-align: top;
  padding: 20px;
}

.modalStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 0.5rem 1rem;
  border-bottom: none;
}

.header-text {
  font-weight: 700;
  line-height: 32px;
  color: #353f50;
}

.bg-red {
  background-color: #f3f5f6;
}

.save-sms-btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;
  background-color: #f3f5f6;
}

.svg-btns {
  width: 80px;
  padding: 0.4rem 0.5rem;
  border-radius: 6px;
  border: none;
}

.cancel-btns {
  background-color: white;
  color: #000;
}

.save-btns {
  background-color: #0275d8;
  color: white;
}
.title {
  color: #000000;
  font-family: "Source Sans Pro";
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  display: block;
}

.sub-title {
  color: #00425f;
  font-family: "Source Sans Pro";
  display: block;
  margin: 0 !important;
  margin-top: 8px;
  font-size: 12px;
}

.compartment span {
  display: block;
}

.compartment .count {
  /* 0 */
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #0172cb;
  display: block;
}

.count-description {
  /* Settlement from 13 Vendor(s) */

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #4b4b4b;
}

.count-time {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.01em;
  margin-top: 8px;
  color: #00425f;
}

.blue-bg-btn {
  background: #00425f;
  border-radius: 2px;
  text-decoration: none;
  position: relative;
  right: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  padding: 10px 20px;
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  right: 20px;
  bottom: 19px;
}

.blue-bg-btn:hover {
  text-decoration: none;
}

.compartment-two,
.compartment-two-orange {
  vertical-align: text-top;
  position: relative;
}

.report-compartment-two-orange div:first-child {
  background: rgba(249, 151, 30, 0.15);
}

.report-compartment-two div:first-child {
  vertical-align: text-top;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.report-compartment-two-orange div:first-child {
  vertical-align: text-top;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.compartment-three {
  height: inherit;
}

.white-bg-btn {
  background: #ffffff;
  border: 2px solid #00425f;
  border-radius: 2px;
  text-decoration: none;
  color: #00425f;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  margin: 0 auto;
  padding: 8px 20px;
}

.report-white-bg-btn {
  background: rgba(229, 229, 229, 0.76);
  border-radius: 2px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  margin: 0 auto;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  min-width: 90px;

  color: #000000;
}

.report-white-bg-btn-orange {
  background: rgba(249, 151, 30, 0.42);
  border-radius: 2px;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  margin: 0 auto;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #000000;
}

.dashboard-short-borders {
  position: absolute;
  right: 0;
  top: 50px;
  bottom: 0;
  height: 71px;
  width: 1px;
  background-color: #5f738c;
}

.blue-bg-btn:hover {
  text-decoration: none;
}

.charts-container {
  margin-top: 50px;
}

.disbursed-cards-container {
  margin-top: 20px;
  position: relative;
}

.disbursed-cards-export-btn {
  position: absolute;
  right: 0;
  background: #5f738c;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  color: #ffffff;
}

.disbursed-cards-export-toggle {
  position: absolute;
  right: 0;
  /* background: #5f738c; */
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  color: #ffffff;
}
.more-transaction-link {
  position: absolute;
  right: 0;
}
.rfid-export-btn {
  position: absolute;
  right: 160px;
  background: #5f738c;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  color: #ffffff;
}

.terminals-export-btn {
  position: absolute;
  right: 190px;
  background: #5f738c;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  color: #ffffff;
}

.contract-export-btn {
  position: absolute;
  right: 190px;
  background: #5f738c;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  color: #ffffff;
}

.create-new-tags-btn,
.back-btn,
.contract-back-btn,
.terminal-back-btn {
  position: absolute;
  right: 0px;
  background: #00425f;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
}

.back-btn {
  top: -20px;
  padding: 5px 20px;
}

.contract-back-btn {
  top: 30px;
  right: 40px;
}

.terminal-back-btn {
  top: -20px;
  right: 10px;
}
.terminal-back-btn svg,
.contract-back-btn svg,
.create-new-tags-btn svg,
.back-btn svg {
  margin-right: 15px !important;
}

.contract-input {
  background: #fafafa;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box;
  border-radius: 4px;
}

.contract-select {
  background: #fafafa;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  color: #000;
}

.revenue-box {
  background: linear-gradient(312.7deg, #03435f -64.04%, #0466dd 114.99%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 16px 20px;
  color: #ffffff !important;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
}

.table-title {
  color: #ffffff;
}

.revenue-box-row {
  background: #5785cf;
  border-radius: 2px;
  margin: 10px 0px;
  padding: 8px 0;
  color: #ffffff;
}

.revenue-box-footer {
  font-size: 12px;
  border-left: 2px solid #fff;
  color: #ffffff;
  padding-left: 16px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  font-family: "Source Sans Pro";
}

thead {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.01em;
  background-color: #eef1f7;
  border-radius: 4px 4px 0px 0px;
  color: #000000;
}

tbody tr td {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;

  color: #4b4b4b;
}

.table-description,
.table-masked-pin,
.table-amount,
.table-date {
  color: #4b4b4b;
  vertical-align: middle !important;
}

.table-pin {
  color: #0172cb;
  font-weight: 600;
}

.status-btn {
  text-align: center;
  border-radius: 10px;
  font-weight: 600;
  padding-top: 3px;
  padding-bottom: 3px;
  max-width: 93px;
}

.success {
  color: #11b01a;
  background: rgba(50, 230, 152, 0.15);
}

.error {
  color: #ee312a;
  background: rgba(238, 49, 42, 0.13);
}

.warning {
  color: #c17707;
  background: rgba(229, 127, 11, 0.13);
}

.table-action {
  color: #0172cb;
  font-weight: 600;
  cursor: pointer;
}

.token-status-box {
  border: 2px solid #5f738c;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 20px;
  width: 100%;
  margin-left: 0;
}

.filter-tags-desc {
  font-weight: bold;
  font-size: 13px;
  padding-right: 15px;
}

.ant-radio-button-wrapper {
  font-weight: 600;
  font-size: 12px;
}

.ant-radio-button-wrapper:hover {
  color: #ffffff;
  background: #00425f;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: #d0021b;
  border-color: #d0021b;
  box-shadow: -1px 0 0 0 #d0021b;
}

.status-sub-title {
  font-size: 13px;
  margin-top: 8px;
  color: #00425f;
}
.card-status-sub-title {
  font-size: 16px;
  margin-top: 8px;
  color: #00425f;
}
.status-sub-title {
  font-size: 13px;
  margin-top: 8px;
  color: #00425f;
}

.status-values {
  font-weight: bold;
  font-size: 18px;
  margin-top: 8px;
  color: #00425f;
}

.pagination-container {
  margin-top: 20px;
  width: 100%;
}

.pagination-container .prev {
  background: #5f738c;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
}

.pagination-container .next {
  background: #00425f;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 13px;
  color: #ffffff;
  cursor: pointer;
}

.bg-rfid-details {
  background-color: rgba(212, 225, 244, 0.53);
}

.bg-rfid-details.active {
  border: 3px solid #0172cb;
}

.rfid-details-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  display: inline-block;
  margin-bottom: 10px;
  color: #00425f;
}

.rfid-details-instruction {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;

  color: #4b4b4b;

  opacity: 0.8;
}

label {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;

  color: #4b4b4b;
}

.form {
  width: 100%;
}

.form input {
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  height: 40px;
  padding: 10px;
}

.form input.disabled {
  background: #f2f2f2;
  border: 1px solid rgba(196, 196, 196, 0.5);
}

.form input:focus {
  border: 1px solid #0172cb;
}

.create-tag-btn {
  background-color: #0172cb;
  color: #ffffff !important;
}

.modal-body {
  background-color: #f7f9ff;
  border-radius: 7px;
}

.transaction-modal .modal-body {
  background-color: #ffffff;
}

.quickteller-blue {
  color: #0172cb;
}

.qt-dark-blue {
  color: rgb(0, 66, 95);
}

.qt-gray {
  color: #4b4b4b;
}

.quickteller-black,
.qt-black {
  color: #000000 !important;
}

.f-14 {
  font-size: 14px;
}

.f-13 {
  font-size: 13px;
}

.transparent-btn {
  background: #e6f1fc;
  border: 1px solid #0172cb;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 20px;
  display: inline-block;
}

.dark-blue-btn {
  background: #00425f;
  border: 1px solid #00425f;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 20px;
  /* display: inline-block; */
  float: right;
  cursor: pointer;
}

.dark-blue-btn span {
  color: "#ffffff" !important;
}

.create-another {
  color: "blue";
  display: inline-block;
  text-align: right;
  font-weight: 600;
  text-decoration: underline;
}

.create-another span {
  cursor: pointer;
}

.contract-modal-heading {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #4b4b4b;
  width: 100%;
}

.contract-modal-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.01em;
  width: 100%;
  color: #000000;
}

.menu-link span {
  font-weight: 700 !important;
}

span svg {
  margin-right: 0 !important;
}

.page-counter {
  background: #ffffff;
  border: 1px solid rgba(128, 128, 128, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 7px;
  color: #5f738c;
  user-select: none;
}
.print-btn {
  width: 167px;
  height: 35px;
  line-height: 35px;
  background: #00425f;
  border-radius: 4px;
  margin: 0 auto;
  cursor: pointer;
}

.no-scheme-list-container {
  background: #ffffff;
  border: 1px solid rgba(217, 217, 217, 0.54);
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  padding: 24px;
  width: 100%;
}

.no-scheme-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 16px 0;
  color: #000000;
}

.create-scheme-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #7f91a8;
}

.no-scheme-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.9%;
  margin-top: 24px;
  color: #4b4b4b;
}
.scheme-list-container {
  width: 100%;
  margin-top: 24px;
}
.scheme-wrapper {
  background: #ffffff;
  border: 1px solid rgba(217, 217, 217, 0.54);
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  padding: 24px;
}
.scheme-wrapper.left {
  margin-right: 14px;
}
.scheme-wrapper.right {
  margin-left: 14px;
}

.scheme-list-container .d-inline-block {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.scheme-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: block;
  margin-bottom: 10px;
  color: #000000;
}

.scheme-code {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: block;
  color: #7f91a8;
  margin-bottom: 10px;
}

.scheme-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
}

.scheme-footer {
  border-top: 0.5px solid #c4c4c4;
  margin-top: 26px;
  padding: 12px 0;
  width: 100%;
  margin-left: 0px !important;
}

.scheme-icon {
  float: left;
}

.contract-no {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #5f738c;
  display: inline-block;
}

.scheme-limit {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #5f738c;
}

.change-password-container {
  background-color: #ffffff;
  /* width: 100%; */
  padding: 24px;
  margin-bottom: 24px;
  position: absolute;
  left: 252px;
  top: 128px;
  margin: 40px;
}

.change-password-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;

  color: #000000;
}

.change-password-button {
  background: #2f80ed;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #ffffff;
  text-align: center;
  padding: 16px;
}

.password-requirements-wrapper {
  background: rgba(229, 239, 252, 0.03);
  border: 1px solid rgba(217, 217, 217, 0.54);
  box-sizing: border-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 20px 48px 10px 20px;
}

.requirement-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 151%;
  /* or 24px */

  color: #000000;
}

.requirement-body {
  color: #7f91a8;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}

.table {
  background-color: #ffffff;
}

thead {
  background-color: #d0d3d4;
}

thead td {
  /* color: #ffffff !important; */
  font-variant-caps: all-small-caps;
  font-size: 15px;
}

.table thead td {
  padding: 0.75rem;
}

/* thead td svg path {
    fill: white;
} */
