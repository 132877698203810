/* login styles  */



.qt-dark-blue {
  color: rgb(0, 66, 95);
}

.qt-gray {
  color: #4b4b4b;
}

.quickteller-black,
.qt-black {
  color: #000000 !important;
}
.imageText {
  color: white;
  margin-right: 74px;
}
.loginShadow {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
}

.image {
  position: fixed;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 800px) {
  html,
  body,
  /* .rowbackground {
    background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url('../../assets/smartfuelImg.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 100%; */
    /* background-position: center; */
  /* }  */
  .loginShadow {
    box-shadow: none;
  }
  .Login-form {
    background: white;
  }
  .imageText {
    display: none;
  }
  .smartFuelBgd {
    display: none;
  }
  .loginShadow {
    width: 700px;
    margin: 0 auto;
    height: 500px;
    margin-top: 50px;
  }
}

a {
  text-decoration: none;
  color: #00425f;
}

a:hover {
  text-decoration: none;
  color: #00425f;
}

/* .css-vj8t7z {
  height: 2px;
  margin-top: 4%;
} */

.modal-content {
  width: 120%;
}

.center-screen row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-height: 100vh;
}

p.footer {
  font-size: 12px;
}
.Login-container p.footer,
.Signin-container p.footer {
  font-size: 10px;
  color: black;
  text-align: center;
}
.Login-container p.footer a,
.Signin-container p.footer a {
  color: #2f75a5;
  font-size: 10px;
}

.Login-container span.submit-caption,
.Signin-container span.submit-caption {
  display: inline-block;
  width: 100%;
}
.Login-container span.submit-caption,
.Login-container span.submit-caption a,
.Signin-container span.submit-caption,
.Signin-container span.submit-caption a {
  color: #2f75a5 !important;
  font-size: 12px;
  margin: 0 auto;
  text-align: left;
  text-align: center;
}

.LogoDiv {
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
}
.Login-form {
  padding: 2.5em 3em;
  /* border-radius: 12px; */
  /* box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.072); */
  height: 510vh;
  max-width: 450px;
  /* background-color: white; */
  /* margin-top:  */
  /* margin: auto; */
  /* margin-left:-5px !important; */
}

.Login-form input {
  padding: 1.5em;
  font-size: 14px;
}

.Login .caption {
  color: white;
  font-size: 24px;
}
.primary-btn {
  background: linear-gradient(211.56deg, #00b8de 22.81%, #4eb4fb 137.18%);
  border: none;
  box-shadow: 0px 11px 25px rgba(78, 180, 251, 0.25);
  color: white !important;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.Login-form button.primary-btn,
.Signin-form input.primary-btn {
  padding: 0.8em;
  width: 100%;
}



.Signin-form {
  padding: 2em 5.5em 0em 5.5em;
  border-radius: 12px;
  /* box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1); */
  height: 550px;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.072);
  /* background-color: white; */
  /* margin-top:  */
}
.form-control {
  border-radius: 0px;
}

.Signin-form input {
  padding: 1.5em;
  font-size: 14px;
  color: #c4c4c4;
}

.Signin .caption {
  color: white;
  font-size: 24px;
}

/* menu styles start */
.Menu {
  position: absolute;
  z-index: 991;
  left: 0;
  top: 80px;
  width: 100px;
  height: 90vh;
  /* border: 1px solid red; */
  background-color: #2f75a5;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.Menu ul.menu-list {
  padding: 0;
}

.Menu ul.menu-list li {
  padding: 0;
  list-style: none;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.Menu ul.menu-list li a {
  display: block;
  padding: 0.6em 0;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.Menu ul.menu-list li a.active {
  background-color: #00b8de;
  border-left: 4px solid #00425f;
  padding: 0.8em 0 0.8em 0.4em;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.Menu ul.menu-list li a.active img {
  margin: 0.2em 0 0.2em 0.5em;
}
.Menu ul.menu-list li a img {
  width: 64%;
  margin: 0.2em 0 0.2em 1em;
}

.FullMenu .Menu {
  width: 0px;
  transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* menu styles end */

/* nav styles start*/
.Navbar {
  z-index: 9999 !important;
}


.Navbar span.navbar-text {
  padding-right: 2em;
  transform: translateY(5px);
}
.Navbar span.navbar-text div.notif {
  width: 40px;
  height: 40px;
  background-image: url('../../assets/Svgs/notif.svg');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 2em;
}
.Navbar span.navbar-text div.person {
  width: 40px;
  height: 40px;
  background-image: url('../../assets/Svgs/personi.svg');
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 2em;
  position: relative;
}
.Navbar span.navbar-text div.person:hover > div {
  opacity: 1;
}
.Navbar span.navbar-text div.person:hover > div {
  display: block;
  transition: 500ms ease;
}
.Navbar span.navbar-text div.person > div {
  width: 150px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.03);
  right: -29px;
  bottom: -40px;
  z-index: 999;
  display: none;
}
.Navbar span.navbar-text div.person > div ul {
  padding: 0;
  margin: 0;
}
.Navbar span.navbar-text div.person > div ul li {
  margin: 1.5em 1em 0.5em 1em;
  padding: 0;
  list-style: none;
  text-align: left;
}

.navbar-ag {
    /* width: 100%; */
    /* height: 4.625rem; */
    z-index: 9990 !important;
    left: 0px;
    top: 0px;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.navbar-ag svg {
    margin-right: 5px;
}

.ant-modal-wrap,
.ant-notification,
.ant-message,
.ant-modal-mask {
  z-index: 9980 !important;
}

.isw-nav--header .isw-nav--pad {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-width: -webkit-min-content;
    min-width: -moz-min-content;
    min-width: -ms-min-content;
    min-width: min-content;
    -webkit-box-pack: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.isw-nav--header {
    background: #fff;
    border-bottom: 1px solid #dadce0;
    display: block;
    color: black;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 320px;
    -webkit-transition: box-shadow 250ms;
    -webkit-transition: -webkit-box-shadow 250ms;
    transition: -webkit-box-shadow 250ms;
    transition: box-shadow 250ms;
    transition: box-shadow 250ms, -webkit-box-shadow 250ms;
    font: 13px/27px Roboto, RobotoDraft, Arial, sans-serif;
    z-index: 986;
}

.isw-nav--header .isw-nav--pad {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0rem 0rem 0rem;/* padding: .7rem 2rem; */
    background-color: #fff;
    -webkit-transition: background-color .4s;
    transition: background-color .4s;
    position: relative;
    width: 100%;
    color: #00425f;
}

.isw-nav--header .isw-nav--pad .isw-logo-menu {
    position: relative;
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-logo {
    display: none;
}

@media (min-width: 992px) {
    .isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-logo {
        display: block;
    }
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button {
    opacity: 1;
    display: block;
}

@media (min-width: 992px) {
    .isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button {
        opacity: .1;
        display: none;
    }
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button .menu-btn {
    background: transparent;
    -webkit-font-smoothing: antialiased;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .08929em;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    padding: 0 16px;
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    position: relative;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    overflow: hidden;
    vertical-align: middle;
    cursor: pointer;
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button .menu-btn .material-icons {
    color: inherit;
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button #menu-close {
    display: none;
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button #menu-close.visible {
    display: block;
}

.isw-nav--header .isw-nav--pad .isw-logo-menu .isw-nav-button #menu-reveal.visible {
    display: none;
}

.isw-logo-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* nav styles end */
.Admin > div.row {
  background-color: #fafafa;
  height: 100vh;
  overflow: hidden;
}
.Admin .admin-content {
  overflow: scroll;
  /* background-color: green */
  padding-bottom: 0em;
  margin-left: 1em;
}

div.search-bar {
  position: relative;
}
.search-bar input {
  appearance: none;
  border: none;
  width: 100%;
}


.card {
  padding: 0 1.4em;
  border: none;
  min-height: 25vw;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.03);
  cursor: pointer;
  margin-bottom: 5vw;
}

.card .card-title {
  padding: 1em 0;
  margin: 0 1.5em;
  border-bottom: 1px solid #c4c4c4;
}
.card .card-body {
  padding: 0;
  margin: 0 1.5em;
}
.card .card-body .title {
  padding: 0.5em 0;
}

.card .card-title p {
  font-size: 14px;
  padding: 0.5em 0 0 0;
}
.card .card-title span {
  float: left;
}

.profile-pic {
  float: left;
  background-color: #00b8de;
  width: 70px;
  height: 70px;
  overflow: hidden;
  padding-left: 0.5em;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
}
.profile-pic-img {
  float: left;
  background-color: white;
  width: 70px;
  height: 70px;
  overflow: hidden;
  padding-left: 0.5em;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.05);
}

.badge-light {
  background-color: #f0f1f1;
  font-weight: 400;
  padding: 0.5em 0.8em;
  color: #00425f;
  margin-right: 1em;
  min-width: 60px;
  margin-top: 1em;
}
.bar {
  margin: 2.5em auto 0 auto;
}
.bar span {
  float: left;
  font-size: 10px;
  text-transform: capitalize;
  color: #8b8a8a;
}
.bar span.bar-line {
  border: 1.5px solid #00b8de;
  margin: 0.6em 0.5em 0 0.5em;
  width: 182px;
}
div.add-card {
  height: 100%;
  min-height: 270px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  box-shadow: 0px 14px 20px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}

img.add-vendor {
  display: block;
  width: 50%;
  margin: 5em auto 0 auto;
  cursor: pointer;
}

div.section-one {
  padding: 0;
  padding-bottom: 2em;
  border-bottom: 1px solid #c4c4c4;
}

/* custom css    generic */

.dark-blue-txt {
  color: #00425f !important;
}
.secondary-btn {
  border: 1px solid #c4c4c4;
  background-color: transparent;
  color: #00425f;
  border-radius: 4px;
  padding: 0;
  font-size: 13px;
}
.full-height {
  height: 100%;
}
.fixed-content-height {
  margin: 1em 0;
  overflow: scroll;
  height: 75vh !important;
}
.fixed-content-height-nav {
  margin: 1em 0;
  overflow: scroll;
  height: 90vh !important;
}

.c-full-width {
  width: 60%;
}
.c-full-width2 {
  width: 100%;
}

.bord {
  border: 2px solid green !important;
}
.large-padding {
  padding-bottom: 20em;
}

.light-blue-txt {
  color: #00b8de;
}

.dark-blue-txt {
  color: #00425f !important;
}

.no-card {
  background-color: transparent;
  box-shadow: none;
}

.no-spaces {
  padding: 0 !important;
  margin: 0 !important;
}
.no-border {
  border: none !important;
}
.secondary-btn {
  border: 1px solid #c4c4c4;
  background-color: transparent;
  color: #00425f;
  border-radius: 4px;
  padding: 0;
  font-size: 13px;
}
.full-height {
  height: 100%;
}
.fixed-content-height {
  margin: 1em 0;
  overflow: scroll;
  height: 75vh !important;
}

.c-full-width {
  width: 60%;
}
.c-full-width3 {
  width: 90% !important;
}
.c-full-width2 {
  width: 100%;
}

.bord {
  border: 2px solid green !important;
}
.large-padding {
  padding-bottom: 20em;
}

.light-blue-txt {
  color: #00b8de !important;
}

.select-custom {
  appearance: none;
  border-radius: 1px solid #dcdcdc;
  border-radius: 20px;
  color: #00425f;
  padding: 0 1.4em;
  /* margin-left:-0.5em; */
}

.header4 {
  color: #00425f;
  font-size: 18px;
  font-weight: bold;
}

.header3 {
  color: #00425f;
  font-size: 18px !important;
  font-weight: bolder !important;
}
.header5 {
  color: white;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 30px !important;
  font-weight: bolder;
  display: inline;
}
.header6 {
  color: #00425f;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 24px !important;
  font-weight: bolder;
  display: inline;
}
.header7 {
  color: #00425f;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px !important;
  font-weight: bolder;
  display: inline;
}
.sky-blue {
  color: #00b8de;
}


.neg-top-margin {
  margin-top: -0.5em;
}

.neg-margin-left {
  margin-left: -1em;
}

.form-control {
  font-size: 12px;
  /* text-transform: capitalize; */
  padding: 0.6em 0.9em;
}
div.tab-card {
  max-height: 170px !important;
  max-height: 170px !important;
  min-height: 170px !important;
}

div.csv-input input#ObiWan.csv-input {
  appearance: none !important;
  -webkit-appearance: none !important;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  color: #00425f;
  border-radius: 4px;
  padding: 0;
  font-size: 13px;
}

div.contract-card {
  height: 200px !important;
  max-height: 200px !important;
}
