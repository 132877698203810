.toggle-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.toggle-label {
    color: #000;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
    margin-bottom: 0;
}

.toggle-switch>input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch .toggle-slider {
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
    background-color: #e1e6ed;
    transition: 0.4s;
    overflow: hidden;
    /* Hide overflow to prevent spinner from overflowing */
}

.toggle-switch .toggle-slider:before {
    position: absolute;
    content: "";
    height: 1.269375rem;
    width: 1.309375rem;
    left: 3px;
    bottom: 2px;
    background-color: #ffffff;
    transition: 0.4s;
}

.toggle-switch input:checked+.toggle-slider {
    background-color: #529e47;
}

.toggle-switch input:focus+.toggle-slider {
    box-shadow: 0 0 1px #529e47;
}

.toggle-switch input:checked+.toggle-slider:before {
    transform: translateX(15px);
}

.toggle-switch .toggle-slider.round {
    border-radius: 28px;
}

.toggle-switch .toggle-slider.round:before {
    border-radius: 50%;
}

/* Spinner styles */
.toggle-switch .spinner {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    /* Hide by default */
}

.toggle-switch.loading .toggle-slider {
    opacity: 0.5;
    /* Reduce opacity of the toggle slider when loading */
}

.toggle-switch.loading .spinner {
    display: block;
    /* Show spinner when loading state is active */
}

/* Customize spinner styles as needed */
.toggle-switch .spinner::after {
    content: "";
    width: 16px;
    /* Adjusted width to match check button input size */
    height: 16px;
    /* Adjusted height to match check button input size */
    border-radius: 50%;
    border: 2px solid #7dc68b;
    /* Lighter green color */
    border-top: 2px solid #ffffff;
    /* Set top border color to white */
    animation: spin 1s linear infinite;
    position: absolute;
    /* Add this line */
    top: 20%;
    /* Add this line */
    left: 50%;
    /* Add this line */
    transform: translate(-50%, -50%);
    /* Add this line */
}

/* Keyframe animation for spinning */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}