body {
  background-color: #f5f5f5;
  letter-spacing: 0.01rem;
}

.container {
  max-width: 1150px !important;
  /* margin-left:6.5em !important ; */
  overflow-x: hidden !important;
}

input:disabled,
select:disabled {
  cursor: not-allowed;
  background-color: lightgrey;
}

.menu-link {
  padding-bottom: 0.5rem;
}

.menu-link .active {
  padding-bottom: 0.25rem;
}

/* Page content styling */
.page-container {
  position: absolute;
  left: 252px;
  top: 128px;
  background: #f5f5f5;
  /* F7F8FC */
  width: calc(100% - 252px);
  min-width: 900px;
  height: calc(100% - 64px);
  padding: 25px 40px 40px;
}

.profile-page-container {
  position: absolute;
  left: 252px;
  top: 128px;

  background: #f5f5f5;
  /* F7F8FC */
  width: calc(100% - 252px);
  min-width: 900px;
  height: calc(100% - 64px);
  padding: 1.5625rem 2.5rem 2.5rem 2.5rem;
}

/* Status Box Styling */
.status-box {
  border: 2px solid #5F738C;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 20px;
  width: 100%;
  margin-left: 0;
}

.form-control,
input {
  color: #00425f !important;
}

.uploadProf {
  position: absolute;
  top: 50px !important;
  left: 50px !important;
}

.form-control::placeholder {
  color: #cdcdcd;
}

html .Loader,
body .Loader {
  margin-left: 5em !important;
  width: 90vw;
  height: 95vh;
  position: absolute;
  margin: 0;
  padding: 0;
}

.Noloader {
  display: none;
  height: 0vh;
}

body .Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  opacity: 1;

  z-index: 8000 !important;
  /* display: none */
}

svg {
  display: none;
}

/* Form Styling */
.form-control:focus {
  color: #000;
  background-color: #FAFAFA;
  border-color: #C4C4C4;
  outline: 0;
  border-radius: 0.25rem;
  box-shadow: none;
}

.form-control {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.form-warning {
  font-size: 0.75rem;
  background: rgba(249, 151, 30, 0.2);
  line-height: 0.9rem;
  border-radius: 0.625rem;
  padding: 0.24rem 0.375rem 0.25rem 0.5rem;
  color: #D97D0A;
  font-weight: 500;
}

.form-warning.inline {
  display: inline-block;
  margin-left: 1.3rem;
}

.form-warning svg {
  margin-right: 0.375rem;
}

.form-warning {
  margin-top: 0.6rem;
}

/* Filter-bars Styling */
.filter-bars {
  cursor: pointer;
}

.blobs {
  -webkit-filter: url(#goo);
  filter: url(#goo);
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  border-radius: 70px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.blobs .blob-center {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  /* background: #00425f; */
  background: #00b8de;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0.9) translate(-50%, -50%);
  transform: scale(0.9) translate(-50%, -50%);
  -webkit-animation: blob-grow linear 3.4s infinite;
  animation: blob-grow linear 3.4s infinite;
  border-radius: 50%;
  box-shadow: 0 -10px 40px -5px #00425f;
}

.blob {
  position: absolute;
  background: #00b8de;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -webkit-animation: blobs ease-out 3.4s infinite;
  animation: blobs ease-out 3.4s infinite;
  -webkit-transform: scale(0.9) translate(-50%, -50%);
  transform: scale(0.9) translate(-50%, -50%);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  opacity: 0;
}

.blob:nth-child(1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.blob:nth-child(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.blob:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.blob:nth-child(4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.blob:nth-child(5) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes blobs {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(-330px - 50%), -50%);
    transform: scale(0) translate(calc(-330px - 50%), -50%);
  }

  1% {
    opacity: 1;
  }

  35%,
  65% {
    opacity: 1;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
    transform: scale(0.9) translate(-50%, -50%);
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(330px - 50%), -50%);
    transform: scale(0) translate(calc(330px - 50%), -50%);
  }
}

@keyframes blobs {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(-330px - 50%), -50%);
    transform: scale(0) translate(calc(-330px - 50%), -50%);
  }

  1% {
    opacity: 1;
  }

  35%,
  65% {
    opacity: 1;
    -webkit-transform: scale(0.9) translate(-50%, -50%);
    transform: scale(0.9) translate(-50%, -50%);
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0) translate(calc(330px - 50%), -50%);
    transform: scale(0) translate(calc(330px - 50%), -50%);
  }
}

@-webkit-keyframes blob-grow {

  0%,
  39% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
  }

  40%,
  42% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
    transform: scale(1, 0.9) translate(-50%, -50%);
  }

  43%,
  44% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }

  45%,
  46% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }

  47%,
  48% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }

  52% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }

  54% {
    -webkit-transform: scale(1.7, 1.6) translate(-50%, -50%);
    transform: scale(1.7, 1.6) translate(-50%, -50%);
  }

  58% {
    -webkit-transform: scale(1.8, 1.7) translate(-50%, -50%);
    transform: scale(1.8, 1.7) translate(-50%, -50%);
  }

  68%,
  70% {
    -webkit-transform: scale(1.7, 1.5) translate(-50%, -50%);
    transform: scale(1.7, 1.5) translate(-50%, -50%);
  }

  78% {
    -webkit-transform: scale(1.6, 1.4) translate(-50%, -50%);
    transform: scale(1.6, 1.4) translate(-50%, -50%);
  }

  80%,
  81% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }

  82%,
  83% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }

  84%,
  85% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }

  86%,
  87% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }

  90%,
  91% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
    transform: scale(1, 0.9) translate(-50%, -50%);
  }

  92%,
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
  }
}

@keyframes blob-grow {

  0%,
  39% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
  }

  40%,
  42% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
    transform: scale(1, 0.9) translate(-50%, -50%);
  }

  43%,
  44% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }

  45%,
  46% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }

  47%,
  48% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }

  52% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }

  54% {
    -webkit-transform: scale(1.7, 1.6) translate(-50%, -50%);
    transform: scale(1.7, 1.6) translate(-50%, -50%);
  }

  58% {
    -webkit-transform: scale(1.8, 1.7) translate(-50%, -50%);
    transform: scale(1.8, 1.7) translate(-50%, -50%);
  }

  68%,
  70% {
    -webkit-transform: scale(1.7, 1.5) translate(-50%, -50%);
    transform: scale(1.7, 1.5) translate(-50%, -50%);
  }

  78% {
    -webkit-transform: scale(1.6, 1.4) translate(-50%, -50%);
    transform: scale(1.6, 1.4) translate(-50%, -50%);
  }

  80%,
  81% {
    -webkit-transform: scale(1.5, 1.4) translate(-50%, -50%);
    transform: scale(1.5, 1.4) translate(-50%, -50%);
  }

  82%,
  83% {
    -webkit-transform: scale(1.4, 1.3) translate(-50%, -50%);
    transform: scale(1.4, 1.3) translate(-50%, -50%);
  }

  84%,
  85% {
    -webkit-transform: scale(1.3, 1.2) translate(-50%, -50%);
    transform: scale(1.3, 1.2) translate(-50%, -50%);
  }

  86%,
  87% {
    -webkit-transform: scale(1.2, 1.1) translate(-50%, -50%);
    transform: scale(1.2, 1.1) translate(-50%, -50%);
  }

  90%,
  91% {
    -webkit-transform: scale(1, 0.9) translate(-50%, -50%);
    transform: scale(1, 0.9) translate(-50%, -50%);
  }

  92%,
  100% {
    -webkit-transform: scale(0) translate(-50%, -50%);
    transform: scale(0) translate(-50%, -50%);
  }
}

/* Button Loading state */

#loader-btn .button {
  -webkit-appearance: none;
  background: transparent none;
  border: 0.2rem solid #2980b9;
  border-radius: 0;
  color: #2980b9;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  margin: 0.5rem;
  padding: 1rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-property: background-color, color;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.16, 0.83, 0.31, 0.99);
}

#loader-btn .button::before {
  background: #2980b9;
  content: '';
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.16, 0.83, 0.31, 0.99);
}

#loader-btn .button[title]::after {
  display: block;
  content: attr(title);
}

#loader-btn .button:not(:disabled):hover,
#loader-btn .button#loader-btn .button--loading {
  color: #fff;
}

#loader-btn .button:not(:disabled):hover::before,
#loader-btn .button#loader-btn .button--loading::before {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

#loader-btn .button:focus {
  outline: none;
}

#loader-btn .button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

input#loader-btn .button:hover {
  background: #2980b9;
}

#loader-btn .button--loading,
#loader-btn .button:disabled {
  cursor: default;
}

#loader-btn .button__label,
#loader-btn .button[title]::after,
#loader-btn .button__loader {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: block;
  transition-property: opacity, visibility, -webkit-transform;
  transition-property: opacity, transform, visibility;
  transition-property: opacity, transform, visibility, -webkit-transform;
  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.16, 0.83, 0.31, 0.99);
}

#loader-btn .button__label {
  opacity: 1;
  visibility: visible;
  transition-delay: 0ms;
}

#loader-btn .button[title]:not(:disabled):hover #loader-btn .button__label,
#loader-btn .button--loading #loader-btn .button__label {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0ms, 0ms, 250ms;
}

#loader-btn .button[title]::after,
#loader-btn .button__loader {
  margin-top: -1em;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(150%);
  transform: translateY(150%);
  transition-delay: 0ms, 0ms, 250ms;
}

.contractDetailsLabel {
  font-size: 14px;
  font-weight: bold;
}

.contractDetailsText {
  font-size: 14px;
}

.cssanimations #loader-btn .button__loader {
  font: 0/0 a;
  width: 2rem;
  height: 2rem;
  margin: -1rem 0 0 -1rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.cssanimations #loader-btn .button__loader::after {
  -webkit-animation: button-loading 1.1s infinite linear;
  animation: button-loading 1.1s infinite linear;
  border-radius: 50%;
  content: '';
  display: block;
  border: 0.3rem solid rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@-webkit-keyframes button-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes button-loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader-btn .button:not(#loader-btn .button--loading):not(:disabled):hover::after,
#loader-btn .button--loading .button__loader {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  transition-delay: 0ms;
}

.vendor-overview-title {

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;

  color: #5F738C;
}

.vendor-overview-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160.8%;
  /* or 23px */

  letter-spacing: 0.01em;

  color: #4B4B4B;
}

.vendor-overview-border {
  width: 209px;
  height: 0px;
  position: absolute;
  left: -110px;
  top: 110px;
  /* z-index: 99999; */
  border: 1px solid rgba(95, 115, 140, 0.47);
  transform: rotate(90deg);
}

.product-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.product-item {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */


  color: #565656;
}

.product-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */


  color: #000000;
}

.product-amount span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  color: #5F738C;
}

.choice-btn {
  padding-left: 10px !important;
}

.choice-btn svg {
  margin-left: 15px !important;
}

.spinner-wrapper {
  min-width: 100%;
  min-height: 200px;
}

.payment-box {
  /* margin: 50px; */
  max-width: (100%-252px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 42px 24px;
  /* gap: 10px; */
  /* height: 180px; */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.0599999987), 0 0.1rem 0.3rem rgba(0, 0, 0, 0.1000000015);
}

.container-2 {
  top: 64px;
  padding: 50px;
  position: relative;
  left: 252px;
  width: calc(100% - 252px);
  max-width: calc(100% - 252px);
  background-color: white;
}

.generate-btn {
  border: 1px solid #C8D2DF;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 132px;
}

.account-box {
  border: 1px solid #E1E6ED;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
  min-height: 132px;
}

.account-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

.payments-header {
  color: #353f50;
  font-family: Averta, 'Source Sans Pro';
  font-size: 20px;
  font-weight: 700;
  /* line-height: 1.6; */
  /* margin-bottom: 8px; */
  white-space: nowrap;
}

.box-upsection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 132px;
}

.payments-sub {
  /* font-family: 'Averta'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #5F738C;
}

.payments-sub.sub-14 {
  font-size: 14px;
}

.payment-label {
  color: #00425f;
  /* font-family: Averta, 'Source Sans Pro'; */
  font-size: 12px;
  font-weight: 400;
}

.payments-sub.dark {
  color: #353F50;
  font-size: 14px;
}

.payments-sub.blue {
  color: #0275D8;
  font-size: 14px;
}

.copy-icon {
  position: absolute;
  right: -16px;
  top: 2px;
  cursor: pointer;
}

.copy-icon:hover {
  background: lightgray;
}

.payment-modal-header {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
}

.payment-modal {
  max-width: 440px;
  top: 20%;
  left: 35% !important;
  height: auto;
}

.payment-modal-container {
  margin-top: 21px;
  /* margin-bottom: 40px; */
  margin-left: 24px;
  margin-right: 24px;
}

.payment-modal-header svg {
  height: 14px;
  /* float: right; */
  margin-bottom: 13px;
  cursor: pointer;
}

.payment-modal-h {
  /* font-family: 'Averta'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #353F50;
}

.acct-name-container {
  background-color: #f9fbfb;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  width: 300px;
  height: 36px;
  padding: 8px;
  margin-top: 24px;
  margin-bottom: 20px;
  /* padding: 0.8rem 3.1rem 0.8rem 0.8rem; */
  /* width: calc(100% - 16.5rem); */
}

.payment-button {
  background: #0275D8;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  /* font-family: 'Averta'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  border: none;
  color: white;
}

.payment-button.max {
  max-width: 198px;
  ;
}

.white-button {
  border: 1px solid #0275D8;
  border-radius: 8px;
  background: #FFFFFF;
  width: 100%;
  /* font-family: 'Averta'; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #0275D8;
  height: 48px;
}

.payment-modal-backdrop {
  background-color: rgba(0, 66, 95, 1);
}

.payment-spinner-container {
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.modal-spinner-text {
  font-size: 16px;
  color: #353f50;
}

.acct-section {
  gap: 20px;
}

.acct-terms {
  font-size: 12px;
  color: #848F9F;
}

.chart-table-head {
  background-color: #F3F5F6;
}

.chart-table-head th {
  font-weight: 800;
  font-size: 12px;
  color: #353F50;
}

.adj-label {
  margin-left: 10px;
  margin-bottom: 0px;
}

.chart-table-head td {
  font-size: 14px;
}

.payment-table-container {
  /* border-radius: 8px 8px 0px 0px; */
  border: 1px solid #E1E6ED;
  border-radius: 8px;
}

.row-margin {
  margin-right: -15px;
  margin-left: -15px;
}

.status-badge {
  height: 28px;
  background: #F1FEF1;
  border-radius: 8px;
  border: 1px solid #BEF2B9;
  color: #36743D;
  padding: 4px 8px;
  gap: 10px;
  width: fit-content;
}

.status-badge.pending {
  background: #FEF6CF;
  border: 1px solid #FDED94;
  color: #AF5F26;
}

.badge-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 8px;
}

.status-badge>.badge-dot {
  background: #36743D;
}

.status-badge.pending>.badge-dot {
  background: #AF5F26;
}

.payment-table-container>.dropdown-divider {
  margin-right: 10px;
  margin-left: 10px;
}

.error-toast-body,
.error-toast-header {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.error-toast-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.payment-modal-container .nav-link.active {
  border: none;
  border-bottom: 2px solid #096DD9;
  color: #4B4B4B;
}

.payment-modal-container .nav-link {
  color: #5F738C;
  font-size: 16px;

}

.payment-modal-container .nav-tabs {
  border-bottom: 2px solid #E5E7EB;
}

.form-container {
  padding-top: 28px;
}

.invoice-modal {
  max-width: unset !important;
  width: 730px !important;
  background: white;
}

.invoice-modal .modal-dialog {
  width: 100%;
  max-width: unset !important;
}

.invoice-modal .modal-body {
  background: white;
}

.invoice-modal .modal-content {
  border: none;
  margin-bottom: 10px;
}

.invoice-label {
  /* font-size: 16px; */
  color: #353F50;
  font-weight: 600;
}

.invoice-form-button {
  width: 160px;
  /* height: 48px; */
  border: none;
  background: #0275D8;
  border-radius: 8px;
  color: white;
}

.invoice-cancel-button {
  width: 101px;
  height: 48px;
  color: #353F50;
  border: 1px solid #E1E6ED;
  border-radius: 8px;
  font-weight: 600;
  /* font-size: 16px; */
}

.button-border {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
}

.invoice-input {
  border: none;
  background: #F3F5F6;
  border-radius: 4px;
  /* height: 56px; */
  width: 100%;
  padding: 14px 12px;
  /* font-size: 16px; */
}

.empty-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-table-caption {
  font-size: 20px;
  font-weight: 700;
  color: #353F50;
}

.empty-table-text {
  font-size: 16px;
  color: #4E5A6C;
}

.payment-stat-dot {
  width: 9px;
  height: 9px;
  border-radius: 9px;
  display: inline-block;
  background: #519E47;
  margin-right: 8px;
}

.payment-stat-dot.pend {
  background: #EC9B40;
}

.payment-stat-dot.failed {
  background: #DC4437;
}

.payment-stat-text {
  font-weight: 400;
  font-size: 12px;
  color: #353F50;
}

.payment-stat-fig {
  font-weight: 700;
  font-size: 24px;
  color: #10345E;
}

.invoice-modal-detail {
  background-color: white;
}

.invoice-divider {
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #C8D2DF;
  margin-top: 2px;
  margin-bottom: 32px;
}

.invoice-details {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  gap: 24px
}

.invoice-detail-h {
  font-size: 32px;
  font-weight: 700;
  color: #353F50;
}

.invoice-detail-button {
  font-size: 14px;
  font-weight: 400;
  height: 40px;
}

.invoice-detail-value {
  font-size: 14px;
  font-weight: 600;
  color: #353F50;
}

.ant-tooltip-inner {
  background: #EBF8FE;
  /* /drop-shadow/base */

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: #353F50;
}

.ant-tooltip-arrow::before {
  background: #EBF8FE;
}

.payment-date {
  border: none;
  background: #F3F5F6;
  height: 56px;
  width: 100%;
  border-radius: 4px;
  padding: 16px 12px;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
  margin-top: 6px;

}

.receipt-modal {
  background-color: white !important;
  max-width: 700px !important;
}

.receipt-modal .modal-content {
  border: none;
  margin: 0px;
}

.receipt-modal .modal-body {
  background-color: white;
}

.receipt-modal .modal-dialog {
  max-width: unset;
  margin-left: 20px;
  background-color: white;
}

.receipt-col-head {
  color: #6D7E95;
  font-size: 16px;
}

.mb-45 {
  margin-bottom: 45px;
}

.bold-600 {
  font-weight: 600;
}

.download-link {
  /* font-family: 'Averta'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-decoration-line: underline;
  color: #0275D8;
}

.f-16 {
  font-size: 16px;
}

.sect-container {
  position: relative;
  width: 100%;
  background: white;
  padding: 50px;
}

.ant-pagination-item-active {
  border: none;
  border-radius: 4px;
  background-color: #00425F;
}

.ant-pagination-item {
  margin-right: 3px;
  margin-left: 3px;
}

.ant-pagination.mini .ant-pagination-prev {
  margin-right: 3px;
}

.ant-pagination.mini .ant-pagination-next {
  margin-left: 3px;
}

.ant-pagination-item-active a {
  font-size: 14px;
  color: white !important;
  font-weight: 600;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  background-color: #F3F5F6;
  border-radius: 4px;
}

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next i {
  width: 15px;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item-link i {
  width: 15px;
}

.filter-container {
  margin-top: 32px;
  margin-bottom: 18px;
}

.filter-select {
  min-width: 150px;
  font-weight: 800;
  font-size: 12px;
  color: #353F50;
  background-color: #E1E6ED;
}

.filter-select input {
  font-size: 12px;
  font-weight: 800;
  color: #353F50 !important;
}

.notification-button {
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  border-radius: 20px !important;
  text-align: center;
}

.notification-button:focus,
.notification-button:hover {
  background-color: #EBF8FE;
}

.notification-badge {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #DC4437;
  color: white;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.notify-header {
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.read-btn {
  background: none;
  border: none;
  font-weight: 600;
  color: #0275D8;
}

.notify-menu .ant-tabs-nav-container {
  padding-right: 10px;
  padding-left: 24px;
}

.notify-menu .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background-color: #0172CB
}

.notify-menu .ant-tabs-bar{
  margin-bottom: 0px;
}

.notify-menu .ant-tabs-tab {
  padding: 8px 0px !important;
  font-size: 12px;
  font-weight: 600;
  color: #353F50;
}

.notify-menu .ant-tabs-tab-active.ant-tabs-tab {
  color: #0172CB;
}

.notify-item{
  border-bottom: 1px #F3F5F6 solid;
  padding-bottom: 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.notify-item.last {
  border-bottom: none;
  padding: 0;
  margin: 0px;
}

.notify-time {
  color: #5F738C;
  font-size: 10px;
  font-weight: 400;
  display: block;
}

.notify-action {
  color: #353F50;
  font-size: 10px;
  /* font-family: Averta; */
  font-weight: 400;
}

.notify-menu .ant-tabs-tabpane.ant-tabs-tabpane-active {
  padding-right: 24px;
  padding-left: 24px;
}

.notify-menu .ant-tab-content{
  max-height: 300px;
  overflow-x: scroll;
}

.notify-h1 {
  font-size: 16px;
  font-weight: 700;
  color: #353F50;
}

.notify-h3 {
  font-weight: 600;
  font-size: 14px;
  color: #353F50;
}

.notify-p {
  color: #5F738C;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word
}

.notify-text{
  font-weight: 400;
  font-size: 14px;
  color: #353F50;
}

.notify-menu-ctn {
  
}

.ant-dropdown{
  position: fixed;
  /* left: 67% !important; */
}

.notification-footer{
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.notify-button, .notify-button-red{
  height: 40px;
  background-color: #0275D8;
  width: 132px;
  border-radius: 6px;
  border: none;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.notify-button-red:hover{
  color: white;
}

.notify-delete{
  height: 40px;
  background-color: white;
  width: min-content;
  border-radius: 6px;
  border: 1px solid #E1E6ED;
  color: #DC4437;
  font-weight: 600;
  font-size: 12px;
}

.delete-confirm-h{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-internal{
  display: flex;
  column-gap: 15px;
  justify-content: center;
  margin: 15px;
  margin-bottom: 25px;
}

.ant-checkbox-input{
  border: 1px solid #AAB7C6;
}