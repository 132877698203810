.title {

}

.sub-title {
    margin: 1.5rem 0;
}

.status-sub-title {
    font-weight: bold;
}

.short-borders {
    position: absolute;
    right: 0;
    top: 40px;
    bottom: 0;
    height: 54px;
    width: 1px;
    background-color: #5F738C;
}
