/* Styling for the table */
.customer-number {
    color: #0172CB;
    font-size: 1rem;
}

.underlined-tab {
    font-family: "Source Sans Pro";
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;
    border-width: 0;
    border-bottom: 1px solid #5F738C;
    position: relative;
    width: 100%;
}

.underlined-tab .tab-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000000;
    border-bottom: 3px solid #00425F;
}

.back-btn i {
    margin-right: 1rem;
}

.table-rfid {
    color: #0172CB;
    font-size: 1rem;
}

/* Styling for the Customer Profile page */
.customer-details {
    font-size: 0.875rem;
    margin-bottom: 5rem;
}

.user-info,
.user-extras,
.user-address,
.user-overview,
.reporting {
    padding: 0.9rem 1.8rem;
    background: rgba(212, 225, 244, 0.53);
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.reporting {
    padding: 0.9rem 1.8rem 1.8rem 1.8rem;
}

.user-info-title,
.user-extras-title,
.user-address-title{
    width: 100%;
    color: #4B4B4B;
}

.user-address-title.bold{
    font-size: 16px;
    font-weight: bold;
}

.user-info-details,
.user-extras-detail,
.user-address-details{
    width: 100%;
    font-weight: 600;
}

.user-info-details div,
.user-extras-detail div,
.user-address-details div{
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-overview .overview-title,
.reporting .reporting-title {
    width: 100%;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.user-overview {
    padding-right: 1.25rem;
    position: relative;
}

.user-overview .inner {
    background: #F1F4FA;
    width: 100%;
    padding: 1.7rem 4.5rem 0.5rem 4rem;
    padding-left: 20px;
}

.reporting .inner {
    background: #F1F4FA;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 0.825rem;
}

.credit-details{
    font-weight: 600;
}

.credit-details .amount {
    position: absolute;
    bottom: 0;
    font-size: 1.0625rem;
    color: #0172CB;
    border: 0.5px solid #0172CB;
    border-radius: 0.25rem;
    padding: 0.4rem 0.7rem;
    background: #ffffff;
}

.credit-details .credit-title {
    position: absolute;
    top: 0.375rem;
}

.customer-stats {
    margin-bottom: 1.5rem;
}

.customer-stats, .contract-details {
    width: 97%;
    margin-left: 0.05rem;
}

.contract-details div {
    width: 100%;
}

.contract-details {
    width: 97%;
    background: rgba(249, 151, 30, 0.2);
    padding: 0.8rem;
    border-radius: 0.25rem;
    /* margin: auto; */
}

.warning {
    position: relative;
}

.warning .message {
    display: inline;
    position: absolute;
    bottom: 20%;
}

.btn-view-contract {
    background: #5F738C;
    padding: 0.625rem 0.6875rem;
    border-radius: 0.25rem;
    border: none;
    color: #ffffff;
    font-size: 0.8rem;
    float: right;
}

.stats {
    background: linear-gradient(171.17deg, #2196FA 18.16%, #00425F 114.2%);
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    border-radius: 0.25rem;
    padding: 0.725rem;
}

.stats .numbers {
    position: relative;
    font-size: 1.6875rem;
}



.short-borders-2 {
    position: absolute;
    right: 49%;
    top: -10px;
    height: 2.6rem;
    width: 0.5px;
    background-color: #ffffff;
}

.payment-icon {
    position: absolute;
    right: 0;
    top: 6.5rem;
}

.reporting a {
    text-decoration: underline;
    color: #0172CB;
}

.profile-picture-and-actions {
    text-align: center;
    padding-right: 3rem;
}

.profile-picture-and-actions img {
    margin-top: 1.9rem
}

.profile-picture-and-actions .avatar {
    margin: 1.9rem auto
}

.avatar Svg {
    margin-right: 0 !important;
}


.upload {
    margin: 1.25rem 0;
    line-height: 1.25rem;
    color: #000000;
}

.upload i svg{
    margin-right: 0.3125rem;
    cursor: pointer;
}

.registered-tags-btn, .remove-btn {
    background: #fff;
    color: #00425F;
    border: 1px solid #00425F;
    font-weight: 600;
    padding: 0.625rem 0.6875rem;
    width: 100%;
    border-radius: 0.25rem;
}

.edit-profile-btn,
.save-btn {
    color: #fff;
    background: #00425F;
    border: none;
    font-weight: 600;
    padding: 0.625rem 0.6875rem;
    width: 100%;
    border-radius: 0.25rem;
    margin: 1.0625rem 0 1.25rem 0;
}

.suspend-btn {
    color: #fff;
    background: #D0021B;
    border: none;
    font-weight: 600;
    padding: 0.625rem 0.6875rem;
    width: 100%;
    border-radius: 0.25rem;
    margin: 0 0 3.0625rem 0;
}

.activate-btn {
    color: #fff;
    background: #3BB54A;
    border: none;
    font-weight: 600;
    padding: 0.625rem 0.6875rem;
    width: 100%;
    border-radius: 0.25rem;
    margin: 0 0 3.0625rem 0;
}

.settings-btn {
    background: #5F738C;
    border: none;
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.625rem 0.6875rem;
    width: 100%;
    border-radius: 0.25rem;
    margin-top: 1.125rem;
}

.settlement-report {
    padding-left: 0;
}

.settlement-report span {
    text-decoration-line: underline;
    color: #0172CB;
    cursor: pointer;
}

.settlement-report-link i svg {
    margin-right: 0.4375rem;
}

/* Saved Accounts */
.saved-accounts {
    margin-top: 0.875rem;
    padding-top: 0 !important;
}

.saved-accounts .header {
    width: 100%;
    margin: 0.75rem 0;
}

.saved-accounts .header i {
    float: right;
}

.accounts-table-title {
    text-transform: uppercase;
    color: #5F738C;
}

.accounts-table-body td{
    font-weight: 600;
}

.accounts-table-body i {
    margin-left: 5px;
}

.deactivate{
    color: #EE312A;
}

.activate {
    color: #11B01A;
}

/* Settings Modal */
.modal {
    display: block;
    top: auto;
    left: 9rem;
    border-radius: 0.5rem;
}
.modal {
    left: 9.5rem !important;
}

.settings-modal {
    max-width: 557px;
}

.settings-modal .modal-content {
    padding: 2.625rem 4rem 14.625rem 9.5rem;
}

.settings-modal .modal-body {
    margin-right: 5.5rem;
    background: #fff;
    padding: 0;
}

.settings-modal-header .title {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0.8125rem;
    color: #00425F;
    margin: 0.625rem 0;
}

.settings-modal-header .title i {
    float: right;
    cursor: pointer;
}

.settings-modal-header .subtitle {
    color: #000;
    font-size: 0.8125rem;
    border-bottom: 0.5px solid #5F738C;
    width: 22.5rem;
}

.card-management-row .title,
.voucher-management-row .title,
.password .title {
    width: 100%;
    display: inline-block;
    font-size: 0.875rem;
    margin: 1rem 0 0.8rem 0;
}

.card-management-row .enable-btn,
.voucher-management-row .enable-btn {
    background: #fff;
    color: #11B01A;
    border: 1px solid #11B01A;
    font-weight: 600;
    padding: 0.5rem 0 0.5rem 1.5rem;
    border-radius: 0.25rem;
    display: inline;
    cursor: pointer;
}

.card-management-row .disable-btn,
.voucher-management-row .disable-btn {
    background: #fff;
    color: #EE312A;
    border: 1px solid #EE312A;
    font-weight: 600;
    padding: 0.5rem 0 0.5rem 1.5rem;
    border-radius: 0.25rem;
    display: inline;
    cursor: pointer;
}

.card-management-row .inactive-btn,
.voucher-management-row .inactive-btn {
    background: rgba(225, 225, 225, 0.69);
    color: #5F738C;
    border: none;
    font-weight: 600;
    padding: 0.5rem 0 0.5rem 1.5rem;
    border-radius: 0.25rem;
    display: inline;
    cursor: pointer;
}

.card-management-row .disable-btn svg,
.voucher-management-row .disable-btn svg,
.card-management-row .enable-btn svg,
.voucher-management-row .enable-btn svg,
.card-management-row .inactive-btn svg,
.voucher-management-row .inactive-btn svg {
    margin-left: 3rem;
}

.radios .first {
    float: left;
}

.radios .second {
    float: right;
}

.confirm-btn {
    width: 100%;
    background: #0172CB;
    color: #fff;
    border-radius: 0.25rem;
    border: none;
    padding: 0.5rem -0;
    margin-top: 1.5rem;
}

/* Edit form */
.edit-form {
    margin-top: 2.125rem;
    padding: 1.0625rem 1.75rem 4rem 1.75rem;
    background: #fff;
    border-radius: 0.25rem;
    margin-bottom: 3rem;
}

.form-control {
    color: #000 !important;
    background-color: #FAFAFA;
    border-color: #C4C4C4;
    outline: 0;
    border-radius: 0.25rem;
}

.form-control input {
    height: 2.1875rem;
}

label {
    color: #4B4B4B;
}

.create-btn {
    position: absolute;
    bottom: -4rem;
    right: 0;
}

.assign-btn button, .create-btn button {
    padding: 0.5rem 2rem 0.5rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ffffff;
    border: none;
    background: #00425F;
    font-weight: 600;
}

.status-bar-short-borders {
    position: absolute;
    right: 0;
    top: 50px;
    bottom: 0;
    height: 65px;
    width: 1px;
    background-color: #5f738c;
  }

  .assign-btn, .assign-btn button {
      width: 100%;
  }

.short-borders {
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 0;
    height: 65px;
    width: 1px;
    background-color: #5f738c;
  }

.terminals-short-borders {
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 0;
    height: 65px;
    width: 1px;
    background-color: #5f738c;
  }

  .dashboard-short-border-line{
    position: absolute;
    right: 0;
    top: 30px;
    bottom: 0;
    height: 110px;
    width: 1px;
    background-color: #5f738c;
  }

.dashboard-short-borders {
    position: absolute;
    right: 0;
    top: 50px;
    bottom: 0;
    height: 95px;
    width: 1px;
    background-color: #5f738c;
  }

  .customer-profile-short-border-1 {
    position: absolute;
    left: 160px;
    top: -10px;
    bottom: 0;
    height: 40px;
    width: 1px;
    background-color: #fff;
  }