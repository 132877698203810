body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'San Francisco';
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/SF\ Pro/SF-Pro-Display-Regular.otf');
  /* IE9 Compat Modes */
}
@font-face {
  font-family: 'San Francisco';
  font-style: bold;
  font-weight: 600;
  src: url('../src/assets/SF\ Pro/SF-Pro-Display-Heavy.otf');
  /* IE9 Compat Modes */
}
body {
  font-family: 'San Francisco';
}
