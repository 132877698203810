.permission-badge {
    color: #595959;
    font-size: 0.75rem;
    padding: 0.5rem 1.4rem 0.5rem 0.625rem;
    border-radius: 0.625rem;
    background: rgba(108, 177, 255, 0.17);
    text-transform: capitalize;
    margin-right: 1.1rem;
    margin-bottom: 0.5625rem;
}

.permission-badge svg {
    margin-right: 0.45325rem;
}

.permission-badge .no-right-padding {
    padding-right: 0.4rem;
}
