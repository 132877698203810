html {
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    font-family: "Source Sans Pro" !important;
}

html::-webkit-scrollbar{
    width: 5px;
    height: 8px;
}

html::-webkit-scrollbar-thumb{
    background-color: #00425F;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: auto;
    font-family: "Roboto", arial, sans-serif;
    outline: none !important;
}

*:focus {
    outline: none !important;
}

*::-webkit-scrollbar {
    display: none;
    height: 16px;
    overflow: visible;
    width: 16px;
}

.container {
    width: 100%;
}

.body {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(117.11deg, #00425F 17.87%, #2193C5 100%);
}

.body-container {
    
    margin: 0 auto;
    position: relative;
}

/* Styles for the table components */
.table td, .table th {
    padding: 1rem .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.status-btn {
    width: 5.125rem;
    text-align: center;
    border-radius: 1rem;
    font-weight: 600;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.info-header{
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #00425F;
}

.info-body {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    margin-top: 2em;
    color: #00425F;
}



@media only screen and (min-width: 1200px){
    .body {
        min-width: 1200px;
    min-height: 864px;
    left: 0px;
    top: 74px;
    }
    .login-banner {
        position: absolute;
        width: 467px;
        height: 381px;
        left: 0px;
        top: 163px;
        background-image: url('../Images/login-image.png');
        background-size: cover;
        box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
    }
    .body-container {
        width: 1150px;
    }
    .login-info {
        position: absolute;
        width: 960px;
        height: 454px;
        left: 189px;
        top: 125px;
        background: #EEF1F7;
        border-radius: 4px;
    }
    .login-panel {
        position: absolute;
        width: 374px;
        height: 573px;
        left: 745px;
        top: 89px;
        padding: 43px 36px;
        background: #FFFFFF;
        border: 0.2px solid #5F738C;
        box-sizing: border-box;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    .info-div {
        position: absolute;
        top: 130px;
        left: 308px;
        width: 221px;
        min-height: 100px;
    }
    
}

@media only screen and (min-width: 800px) and (max-width: 1199px){
    .body-container {
        width: 800px;
    }
    .body {
        min-width: 800px;
        min-height: 800px;
        left: 0px;
        top: 74px;
    }
    .login-info {
        position: absolute;
        width: 700px;
        height: 454px;
        left: 50px;
        top: 125px;
        background: #EEF1F7;
        border-radius: 4px;
    }
    .login-panel {
        position: absolute;
        width: 374px;
        height: 573px;
        left: 345px;
        top: 89px;
        padding: 43px 36px;
        background: #FFFFFF;
        border: 0.2px solid #5F738C;
        box-sizing: border-box;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
    .info-div {
        position: absolute;
        top: 130px;
        left: 48px;
        width: 221px;
        min-height: 100px;
    }
}

@media only screen and (min-width: 370px) and (max-width: 799px){
    .body-container {
        width: 370px;
    }
    .body {
        min-width: 370px;
        min-height: 700px;
        left: 0px;
        top: 74px;
    }
    .login-panel {
        position: absolute;
        width: 370px;
        height: 573px;
        left: 0px;
        top: 29px;
        padding: 43px 36px;
        background: #FFFFFF;
        border: 0.2px solid #5F738C;
        box-sizing: border-box;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
    .info-div {
        position: absolute;
        top: 130px;
        left: 48px;
        width: 221px;
        min-height: 100px;
    }
}

.navbar {
    width: 100%;
    height: 4.625rem;
    z-index: 99999999999 !important;
    left: 0px;
    top: 0px;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);   
}




.menu-bar {
    position: relative;
    top: 65px;
    left: 250px;
    width: calc(100% - 250px);
    min-width: 900px;
    height: 64px;
    background: linear-gradient(179.34deg, #1A4996 -40.31%, #03435F 112.95%);
    padding: 10px 30px 14px 30px;
}



  .menu-link {
      color: #ffffff;
      line-height: 18px;
      font-size: 16px;
      font-weight: 600;
      font-family: "Source Sans Pro";
      margin-top: 20px;
      margin-bottom: 10px;
      padding-bottom: 4px;
      
  }

  .rfid-menu-link {
    color: #ffffff;
    line-height: 18px;
    font-weight: 600;
    font-family: "Source Sans Pro";
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 4px;
    cursor: pointer;
  }

  .rfid-menu-link span {
    padding-bottom: 0.38rem;
    display: inline-block;
  }

  .rfid-menu-link span.active {
    border-bottom: 3px solid #00425F;
  }

  .menu-link span {
      color: #ffffff;
      font-weight: 500;
      font-family: "Source Sans Pro";
      padding-bottom: 0.38rem;
      display: inline-block;
  }

  .menu-link a {
      margin-right: 20px
  }

  .menu-link span.active{
    border-bottom: 3px solid #3897EB;
  }

  .menu-input-container {
      float: right;
      position: relative;
      top: -16px;
      height: 35px;
      width: 313px;
      background: rgba(139, 167, 213, 0.43);
      color: #ffffff;
      padding-left: 16px;
  }

  .menu-input {
    border: none;
    background: rgba(139, 167, 213, 0);
    height: 100%;
    color: #ffffff;
  }
  
  .input-icon {
    float: right;
    margin-top: 8px;
}

.ant-calendar-picker-input:hover {
    border-color: #d9d9d9 !important;
}

input[type="text"], input[type="password"], textarea, select { 
    outline: none;
    color: #ffffff;
}

 ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1; /* Firefox */
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #ffffff;
  }
  
::-ms-input-placeholder { /* Microsoft Edge */
    color: #ffffff;
  }


.guest-footer {
    width: 100%;
    height: 56px;
    background: #3472AB;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 50px;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.footer-link {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 56px;
    margin-right: 63px;
    letter-spacing: 0.01em;

    color: #FFFFFF;
}

.registration-info {
    float: right;
    margin-top: 26px;
}

.registration-text {
    width: 154px;
    height: 23px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
}

.registration-btn {
    width: 93px;
    margin-left: 20px;
    padding: 10px 20px;
    height: 40px;
    background: #EEF1F7;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}

.logo {
    position: absolute;
    width: 186px;
    height: 74px;
    left: 7px;
    top: 0;
    background: url(../Images/logo-transparent.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.logo-co {
    /* position: absolute; */
    margin: -5px 0 5px;
    width: 186px;
    height: 64px;
    left: 7px;
    top: 0;
    background: url(../Images/logo-transparent.png);
    background-repeat: no-repeat;
    background-size: cover;
}

input.menu-input {
    color: #FFFFFF !important;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 158.5%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.0rem;
  clear: both;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}


.dropdown-item {
  color: #00425f;
}

.dropdown-item:hover {
  background: #f8f9fa;
  cursor: pointer;
}

    .login-title {
        height: 23px;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.01em;
        display: block;
        color: #000000;
    }

    .login-instruction {
        height: 23px;
        font-family: "Source Sans Pro";
        display: block;
        margin-top: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    .input-container {
        margin-top: 25px;
    }

    .display-block {
        display: block;
    }

    .input-label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        font-family: "Source Sans Pro";
        letter-spacing: 0.01em;
        color: #4B4B4B;
    }

    .account-input {
        border: 1px solid rgba(196, 196, 196, 0.4);
        box-sizing: border-box;
        border-radius: 2px;
        height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
    }

    /* .button-container {
        
    } */

    .text-center {
        text-align: center;
    }

    .account-btn {
        background: #03435F;
        border-radius: 2px;
        height: 40px;
        border: none;
        width: 100%;
        color: #FFFFFF !important;
        font-family: "Source Sans Pro";
    }

    .forgot-password-text {
        margin-top: 33px;
        margin-bottom: 22px;
        display: block;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #00425F;
    }

    .panel-footer-container {
        position: absolute;
        bottom: 28px;
        left: 0;
        width: 100%;
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        letter-spacing: 0.01em;
    }

    .panel-footer-link {
        color: #00425F;
        text-decoration: underline;
    }

    .error-container {
        background: rgba(255, 0, 0, 0.2);
        border-radius: 2px;
        margin-top: 18px;
        padding: 16px 21px;
    }

    .success-container {
        background: green;
        border-radius: 2px;
        margin-top: 18px;
        padding: 16px 21px;
    }

    .success-container span {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #fff;
    }

    .success-close {
        color: #fff;
        float: right;
        padding-left: 16px;
        cursor: pointer;
    }

    .error-close {
        color: #4B4B4B;
        float: right;
        padding-left: 16px;
        cursor: pointer;
    }

    .asterisk {
        color: #D21C1C;
        font-size: 24px;
        position: relative;
        margin-left: 5px;
        bottom: -5px;
    }