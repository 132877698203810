.charts-container {
    margin-top: 50px;
    margin-bottom: 20px;
}

.account-details {
    margin-bottom: 5rem;
}

.table-date {
    color: #4B4B4B;
}

.table-menu-link {
    font-weight: 600;
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
}

.table-menu-link span {
    padding-bottom: 0.38rem;
    color: #000000;
    border-bottom: 3px solid #00425f;
}

.success {
    color: #11B01A;
    background: rgba(50, 230, 152, 0.15);
}

.error {
    color: #EE312A;
    background: rgba(238, 49, 42, 0.13);
}

.table-action {
    color: #0172CB;
    font-weight: 600;
}

.table-title {
    color: #000000;
}

td {
    color: #4B4B4B;
}

/* Icon Styles */
.filter-bars {
    margin-left: 0.25rem;
}

/* Action Buttons */

.action-buttons {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
}

.export-btn {
    background: #5F738C;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ffffff;
    border: none;
    margin-right: 2rem;
}

.add-btn {
    background: #00425F;
    padding: 0.5rem 2rem 0.5rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ffffff;
    border: none;
}


/* Icon Styles */
.filter-bars {
    margin-left: 0.25rem;
}

/* Action Buttons */

.action-buttons {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
}

.add-btn {
    background: #00425F;
    padding: 0.5rem 2rem 0.5rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ffffff;
    border: none;
}

.back-btn {
    background: #00425F;
    padding: 0.5rem 2rem 0.5rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ffffff;
    border: none;
}

.table-date {
    color: #4B4B4B;
}

.user-extras .inner {
    padding-left: 0;
    width: 75%;
    position: relative;
}

.suspend-btn-column, .action-btn-column {
    width: 20%;
    position: absolute;
    top: 7.2rem;
    right: 8.5rem;
}

button {
    font-size: 0.8125rem;
}

.suspend-btn-column button, .action-btn-column button {
    margin: 0;
}

.edit-btn {
    width: 100%;
}

.edit-btn button {
    padding: 0.5rem 2rem 0.5rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ffffff;
    border: none;
    background: #5F738C;
    font-weight: 600;
    float: right;
    margin-right: 1.8rem;
    margin-top: 1.1875rem;
}

.upload {
    margin: 1.25rem 0;
    line-height: 1.25rem;
    color: #000000;
    font-size: 0.875rem;
}

.permissions {
    padding: 0.9rem 0rem 0.9rem 1rem;
    width: 100%;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
    position: relative;
    margin-right: 0 !important;
}

.permissions .overview-title {
    width: 100%;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.permission-badge.no-right-padding {
    padding-right: 0.4rem;
}

.permission-badge .x-button {
    margin-left: 0.7rem;
    cursor: pointer;
}

.search-bar {
    height: 35px;
    width: 313px;
    background: #7F91A8;
    color: #ffffff;
    padding-left: 16px;
    margin: 1.25rem 0;
    width: 100%;
}

.input-icon {
    float: right;
    margin-top: 8px;
    right: 0;
    position: absolute;
    top: -0.10rem;
}

/* Modal Styling */
.user-modal .header {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #3A3B39;
}

.modal {
    left: 13.5rem !important;
}

.modal-action-buttons {
    margin: 0 2.75rem;
}

.modal-action-buttons button {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    color: #fff;
    width: 100%;
    border: none;
}

.modal-action-buttons .back {
    background: #5F738C;
}

.modal-action-buttons .create {
    background: #00425F;
}

.modal-content {
    margin-bottom: 5rem;
}


select {
    color: #000000;
    height: 40px;
    width: 100%;
}


.form-control {
    height: 40px;
}


.permission-dropdown-item:hover {
    background: #F2F9FF;
}

.permission-dropdown-item {
    color: #000;
    padding-top: 5px;
    padding: 5px 10px;
    border-top: 1px solid #E1E4E8;
    font-size: 14px;
}

.permission-dropdown-item input:checked {
    background-color: #5F738C;
}

.permission-dropdown-item input {
    margin-right: 10px;
}

.permission-dropdown {
    background: #ffffff;
    border: 1px solid #E1E4E8;
    border-radius: 2px;
    position: relative;
    top: -40px;
    width: 100%;
    z-index: 9;
}

.permission-dropdown-title {
    color: #00425F;
    font-size: 14px;
    padding: 5px 10px;
}